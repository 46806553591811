import React from 'react';
import Navigation from './routes/Routes';

function App() {

  return (
    <Navigation />
  );
};

export default App;

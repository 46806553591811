import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Logo from '../../images/logo.png';
import Fb from '../../images/fb.png';
import Insta from '../../images/insta.png';
import Print from '../../images/print.png';

function ResetPassword(props) {
    const [resetLink, setResetLink] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [modal, setModal] = useState(null)

    useEffect(() => {
        const reciecePath = window.location.search
        setResetLink(reciecePath.substring(7))
    }, [])

    const sendPassword = () => {
        let body = { resetLink, newPassword, confirmPassword }
        if (newPassword === '') {
            return setModal({
                error: true,
                message: 'Veuillez entrer votre nouveau password'
            })
        }
        if (confirmPassword === '') {
            return setModal({
                error: true,
                message: 'Veuillez confirmer votre nouveau password'
            })
        }
        if (newPassword !== confirmPassword) {
            return setModal({
                error: true,
                message: 'Le  nouveau password et la confirmation doivent être identiques'
            })
        }
        axios.put('http://91.234.195.209:1005/user/reset-password', body)
            .then(res => setModal(res.data))
            .catch(err => setModal(err.response.data))
    }
    return (
        <div style={{ height: '97vh', margin: 0 }}>
            <header style={{ height: 120, backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '60%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <img src={Logo} style={{ height: 100, width: 100 }} alt='lookhubbe' />
                    <a href='https://www.lookhubbe.com/' target="_blank" rel="noopener noreferrer" style={{ color: '#EF0888', fontWeight: 'bold' }}>Découvrez Lookhubbe</a>
                </div>
            </header>
            <body style={{ backgroundColor: '#f2c1de', height: 'calc(100% - 240px)', textAlign: 'center' }}>
                <div style={{ paddingTop: 20 }}>
                    <h1 style={{ color: '#EF0888' }}>Reset password</h1>
                </div>
                <div style={{ height: '80%', width: '80%', margin: '0 auto', display: 'flex', justifyContent: 'center' }}>
                    <div style={{ height: '100%', width: '80%', alignItems: 'space-around' }}>
                        <input type='password' placeholder='Entrez votre nouveau password' style={{ display: 'block', width: '80%', margin: '0 auto', fontSize: 20, padding: 10, border: '2px solid #EF0888', marginTop: 100 }} onChange={e => setNewPassword(e.target.value)} />
                        <input type='password' placeholder='Confirmez votre nouveau password' style={{ display: 'block', width: '80%', margin: '0 auto', fontSize: 20, padding: 10, border: '2px solid #EF0888', marginTop: '2%' }} onChange={e => setConfirmPassword(e.target.value)} />
                        <button style={{ display: 'block', width: '80%', color: 'white', fontWeight: 'bold', margin: '0 auto', fontSize: 20, padding: 10, borderRadius: 50, border: '2px solid #EF0888', backgroundColor: '#EF0888', marginTop: '2%' }} onClick={sendPassword}>Envoyer</button>
                        {modal &&
                            <div style={{ backgroundColor: modal.error ? 'red' : 'green', width: '80%', margin: '0 auto' }}>
                                <h3 style={{ color: 'white', padding: '2px 0 2px 0' }}>{modal.message}</h3>
                            </div>
                        }
                    </div>
                </div>
            </body>
            <footer style={{ height: 120, backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <a href='https://www.facebook.com/Lookhubbe/' target="_blank" rel="noopener noreferrer" style={{ marginRight: 20 }}>
                    <img src={Fb} style={{ height: 40, width: 40 }} alt='facebook' />
                </a>
                <a href='https://www.instagram.com/lookhubbe/' rel="noopener noreferrer" target="_blank">
                    <img src={Insta} style={{ height: 40, width: 40 }} alt='instagram' />
                </a>
                <a href='https://www.pinterest.fr/Lookhubbe/' rel="noopener noreferrer" target="_blank" style={{ marginLeft: 20 }}>
                    <img src={Print} style={{ height: 40, width: 40 }} alt='pinterest' />
                </a>
            </footer>
        </div>
    );
}

export default ResetPassword;
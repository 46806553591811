import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ResetPassword } from '../components';

const Navigation = () => {
    return (
        <Router>
            <Switch>
                <Route
                    exact
                    path={'/'}
                    component={ResetPassword}
                />
            </Switch>
        </Router>
    )
}
export default Navigation;